import React from "react";

const About = () => {
  return (
    <div>
      My kids say I'm a good enough guy, that works for me, should work for you
    </div>
  );
};

export default About;
